import React, { useState } from 'react';
import { Icons, colors } from '@united-talent-agency/components';
import { CALL_ROW } from '../../../support/cypressTags';
import { StarIconTableElement, StarIconContainer } from '../styles';

/**
 * Inline star toggle
 *
 * @param {number || null} favorite: denotes whether or not the callTodo has be
 *  marked as a 'favorite'
 * @param {function} onSave: on save hook
 * @returns {JSX.Element}
 * @constructor
 */
const Starred = ({ favorite, onSave }) => {
  const [starred, setStarred] = useState(favorite === 1);

  return (
    <StarIconTableElement>
      <StarIconContainer
        data-cy={starred ? CALL_ROW.STAR : CALL_ROW.NO_STAR}
        onClick={() => {
          const isStarred = !starred;
          setStarred(isStarred);
          onSave({ favorite: isStarred ? 1 : 0 }).then((r) => r?.error && setStarred(starred));
        }}
      >
        <Icons.StarIcon
          id="star-icon"
          inline
          width={14}
          height={14}
          stroke={starred ? colors.nobel : colors.darkGrey}
          color={starred ? colors.sunshine : colors.white}
        />
      </StarIconContainer>
    </StarIconTableElement>
  );
};

export default Starred;
