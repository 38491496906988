import { get, put } from '.';

export const getStatuses = async (deskId) => {
  const response = await get(`/v2/desks/${deskId}/statuses/`);
  return response.body;
};

export const updateStatuses = async (id, payload) => {
  const response = await put(`/v2/desks/${id}/statuses/`, payload);
  if (response.status === 400) {
    throw Error(response.body.error);
  }
  return response.body;
};

export const countStatusCalls = async (deskId, statusId) => {
  const response = await get(encodeURI(`/v2/desks/${deskId}/statuses/${statusId}`));
  return response.body;
};
