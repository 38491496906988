import { colors } from '@united-talent-agency/components';
import styled from 'styled-components/macro';

export const MenuColumn = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 0;
  flex-direction: column;
  align-items: flex-end;
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: 95px;
  width: 310px;
  max-width: 50%;
  margin-right: 3px;
  box-sizing: border-box;
  background: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  z-index: 2;
`;

export const MenuButton = styled.div`
  cursor: pointer;
  flex: 0.1;
`;

export const MenuItem = styled.div`
  display: flex;
  font-size: 12px;
  padding: 7px 15px;
  cursor: pointer;

  :first-child {
    padding-top: 14px;
  }

  :last-child {
    padding-bottom: 14px;
  }

  &:hover {
    background-color: ${colors.fadedLinkBlue};
  }
`;

export const MenuItemIconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
`;
