import React from 'react';

import { Spinner, Icons } from '@united-talent-agency/components';
import { Button, Label, Prefix, Suffix } from './styles';
const { ChevronDown } = Icons;

const LoadMore = ({ loading = false, onClick }) => (
  <Button onClick={onClick} disabled={loading}>
    <Prefix>{loading && <Spinner size={15} />}</Prefix>
    <Label>Load more calls</Label>
    <Suffix>
      <ChevronDown />
    </Suffix>
  </Button>
);

export default LoadMore;
