import React from 'react';
import { useToasts } from 'react-toast-notifications';

const withHooksHOC = (Component) => {
  const Wrapper = (props) => {
    const { addToast } = useToasts();
    return <Component addToast={addToast} {...props} />;
  };

  return Wrapper;
};

export default withHooksHOC;
