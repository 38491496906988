import { recordEvent } from '@united-talent-agency/julius-frontend-store';

/**
 * referencable labels for tags
 */
export const teleTags = {
  createCall: 'phone-sheet-web.call-create',
  editItem: 'phone-sheet-web.item-edit',
  clearFilters: 'phone-sheet-web.filter-clear',
  changeFilters: 'phone-sheet-web.filter-change',
  searchArchives: 'phone-sheet-web.archive-search',
  importFromArchives: 'phone-sheet-web.archive-import',
  searchCalls: 'phone-sheet-web.call-search',
  updateSettingsField: 'phone-sheet-web.desk-settings-update-field',
  updateStatus: 'phone-sheet-web.desk-status-update',
  selectPane: 'phone-sheet-web.select-pane',
  preview: 'phone-sheet-web.preview-set',
  toggleFavorite: 'phone-sheet-web.favorite-toggle',
  changeDesks: 'phone-sheet-web.desk-change',
};

/**
 * captures telemetry, dispatching to the store for processing. while dispatch
 * executes asychronously, we do not need to wait for it to return
 *
 * @param {dispatch fn} dispatch
 * @param {string} tag
 * @param {mixed} data
 * @param {userId} userId
 */
export function captureTelemetry(dispatch, tag, userId, data) {
  dispatch(recordEvent(tag, 'phone-sheet-web', 'info', null, data, userId));
}

export function createCapture(dispatch, userId) {
  return (tag, data) => {
    captureTelemetry(dispatch, tag, userId, data);
  };
}
