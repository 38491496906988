import { colors } from '@united-talent-agency/components';
import styled from 'styled-components/macro';

export const Button = styled.button`
  width: 100%;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  font-size: 15px;
  font-weight: bold;
  color: ${colors.utaBlack};
  background-color: ${colors.focusGrey};
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:active {
    background-color: ${colors.activeGrey};
  }
`;

export const Label = styled.span`
  margin: 0 8px;
`;

export const Prefix = styled.div`
  width: 15px;
  padding-top: 4px;
`;

export const Suffix = styled.div`
  width: 15px;
  padding-bottom: 2px;
`;
