import { colors } from '@united-talent-agency/components';
import styled from 'styled-components';

export const DeleteContainer = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 12px;
  color: ${({ someSelected }) => (someSelected ? '' : colors.disabledGrey)};

  &:hover {
    cursor: ${({ someSelected }) => (someSelected ? 'pointer' : 'default')};
  }
`;
