import styled from 'styled-components/macro';

export const StatusSelectorContainer = styled.div`
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
`;

export const StatusMenuContainer = styled.div`
  z-index: 1;
  white-space: nowrap;
`;

export const StatusList = styled.div`
  display: block;
  position: absolute;
  box-sizing: border-box;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  background-color: white;
  z-index: 1;
`;

export const BulkStatusUpdateItem = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;

  &:hover {
    background-color: rgba(41, 161, 219, 0.15);
  }
`;
