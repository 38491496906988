import React, { useEffect, useState } from 'react';
import { styled } from 'react-free-style';

import { fromUnixTime } from '../../../support/date';

import { Spinner, colors } from '@united-talent-agency/components';
import { Time } from '@united-talent-agency/julius-frontend-components';
import { getHistory } from '../../../api/history';
import { CALL_HISTORY_TABLE_MODAL } from '../../../support/cypressTags';

const labelObj = {
  relationshipId: 'Relationship',
  contactId: 'Contact',
  addressId: 'Address',
  personId: 'Person',
  recipientId: 'Person',
  companyId: 'Company',
};

const _valueChange = (v1, v2) => ' was changed' + _bold('from') + v1 + _bold('to') + v2;
const _date = (v) => new Date(v).toLocaleString('en-US');
const _bold = (str) => `<b style="font-weight: 600"> ${str} </b>`;

export const _auditChange = (audit = {}) => {
  const auditChangeObject = {};

  if (audit.recordCreated) {
    return { Call: ' was created' };
  }

  if (audit.recordDeleted) {
    return { Call: ' was deleted' };
  }

  if (audit.recordRecovered) {
    return { Call: ' was recovered' };
  }

  if (audit.delta) {
    Object.keys(audit.delta).forEach((key) => {
      if (['contact', 'updatedAt', 'callerId', 'deletedBy'].includes(key)) {
        return;
      }

      const value = audit.delta[key];
      const auditLabel = labelObj[key] ?? key;

      if (key === 'notes') {
        const noteChange = _formatNotesChange(audit.delta[key]);
        if (noteChange) {
          auditChangeObject['Note'] = noteChange;
        }
      } else {
        if (value && value.length === 1 && auditLabel !== 'favorite') {
          auditChangeObject[auditLabel] = value + ' was added to the record';
        } else {
          if (value[0] && value[1] && value.length === 2) {
            if (auditLabel === 'occurrence_date') {
              auditChangeObject['Date'] = _valueChange(_date(value[0]), _date(value[1]));
            } else {
              auditChangeObject[auditLabel] = _valueChange(value[0], value[1]);
            }
          } else if (value[0] && value[1] === 0 && value.length === 2) {
            if (auditLabel === 'favorite') {
              auditChangeObject['Call'] = ' was unstarred';
            }
          } else if (value[0] && !value[1] && value.length === 2) {
            if (auditLabel !== 'favorite') {
              auditChangeObject[auditLabel] = value[0] + ' was deleted';
            }
          } else {
            if (
              auditLabel === 'favorite' &&
              ((value[0] === 1 && value[1] === undefined) || (value[0] === 0 && value[1] === 1))
            ) {
              auditChangeObject['Call'] = ' was starred';
            }
          }
        }
      }
    });
  }

  return auditChangeObject;
};

/**
 * Formats Note Changes for the UI
 *
 *
 * @param {Object} notesDelta
 * @returns {str} formatted string to show in the history modal.
 * @private
 */
const _formatNotesChange = (notesDelta) => {
  const deltaArray = Object.values(notesDelta);
  const changeArray = deltaArray.filter((v) => v instanceof Array);
  const changeObject = deltaArray.filter((v) => v?.note);

  let formattedChange;

  // Create and Delete changes
  if (changeArray.length && changeArray[0].length > 0) {
    const note = changeArray[0][0].note;
    if (changeArray[0].length === 1) {
      formattedChange = `'${note}' was added to the record`;
    }
    if (changeArray[0].length === 3) {
      formattedChange = `'${note}' was removed from the record`;
    }
  }
  // Update changes
  else if (changeObject.length > 0) {
    const [n1, n2] = [...changeObject[0].note];
    formattedChange = ' was changed' + _bold('from') + n1 + _bold('to') + n2;
  }
  return formattedChange;
};

export const _historyFilter = (history) => {
  return history.filter((h) => {
    const isEmptyAudit =
      h.delta === undefined ||
      !Object.keys(h?.delta).some((key) => !['updatedAt', 'contact'].includes(key));
    return !isEmptyAudit;
  });
};

/**
 * Displays the change history of a callTodo
 * @param {string} callId: id of the callTodo to fetch and display history for
 * @param {Object} styles: wrapped and provided component styling.
 * @returns {JSX.Element}
 * @constructor
 */
const CallHistoryTable = ({ callId, styles }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    getHistory(callId).then((_history) => {
      setHistory(_historyFilter(_history));
    });
  }, [callId]);

  if (!history) {
    return <Spinner size={20} />;
  }

  return (
    <div data-cy={CALL_HISTORY_TABLE_MODAL.MODAL} className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.body}>
          <table className={styles.table}>
            <thead className={styles.header}>
              <tr className={styles.th}>
                <th style={{ width: '25%' }}>Date</th>
                <th style={{ width: '50%' }}>Change</th>
                <th>Changed By</th>
              </tr>
            </thead>
            <tbody>
              {history.map((item, idx) => {
                const key = item._id + idx;
                const delta = _auditChange(item);
                const hasDelta = Object.keys(delta).length > 0;
                const userName = item.userId
                  ? `${item.userId.first_name} ${item.userId.last_name}`
                  : 'Unknown';

                return hasDelta ? (
                  <tr key={key} data-cy={CALL_HISTORY_TABLE_MODAL.ROW} className={styles.td}>
                    <td>
                      <Time date={fromUnixTime(item.timestamp)} />
                    </td>
                    <td>
                      <ChangeDelta index={idx} delta={delta} styles={styles} />
                    </td>
                    <td>{userName}</td>
                  </tr>
                ) : (
                  <></>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

/**
 * Displays a singular record change to the CallTodo
 *
 * - note - several attributes on CallTodo can be included in 1 change
 *  record, that's why changes are looped over.
 *
 * @param {Object} delta: where the keys are CallTodo properties that changed and the
 *  value is a formatted string denoting old -> new value changes.
 * @param {number} index: used for unique keying
 * @param {Object} styles: wrapped and provided component styling
 * @returns {JSX.Element}
 * @constructor
 */
const ChangeDelta = ({ delta, index, styles }) => {
  return (
    <ul className={styles.changeList} key={index}>
      {Object.keys(delta).map((key, index) => (
        <li id={'change'} className={styles.changeLine} key={index}>
          <span className={styles.changeKey}>{key.charAt(0).toUpperCase() + key.slice(1)}</span>{' '}
          <span dangerouslySetInnerHTML={{ __html: delta[key] }} />
        </li>
      ))}
    </ul>
  );
};

const withStyles = styled({
  wrapper: {
    zIndex: 9,
    position: 'absolute',
    top: '100%',
    right: 55,
  },
  container: {
    width: 860,
    maxHeight: 260,
    overflow: 'auto',
    backgroundColor: '#fff',
    border: '0.5px solid rgba(0, 0, 0, 0.1)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    marginBottom: 20,
  },
  table: {
    width: '100%',
    position: 'relative',
    borderCollapse: 'collapse',
  },
  header: {
    position: 'sticky',
    top: 0,
  },
  th: {
    color: colors.darkGrey,
    backgroundColor: colors.background,
    fontSize: 15,
    '>th': {
      textAlign: 'left',
      padding: '8px 16px',
      fontWeight: 'normal',
    },
  },
  td: {
    '>td': {
      fontSize: 12,
      fontWeight: 300,
      color: colors.text,
      padding: '8px 16px',
    },
    backgroundColor: colors.contentBackground,
    borderBottom: `1px solid ${colors.background}`,
  },
  changeList: {
    listStyle: 'none',
    margin: '0 0 0 -40px',
  },
  changeLine: {
    whiteSpace: 'pre-line',
  },
  changeKey: {
    fontWeight: 900,
  },
});

export default withStyles(CallHistoryTable);
