import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { ContactType, ContactTypeSelector, ContactInput } from '@united-talent-agency/components';
import {
  existingContactNotPhone,
  existingContactPhone,
  formatContactForDisplay,
} from '../../../support/contact';
import PhoneNumber from 'awesome-phonenumber';
import { isValidPhoneNumber, AsYouType, getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/mobile/examples';
// import { toUnixTime } from '../../../support/date';
import { getPrivateContacts } from '../../../api/people';
import EllipsisText from '../EllipsisText';
import { CALL_ROW, CONTACT_INPUT } from '../../../support/cypressTags';
import { ContactDisplayContainer, ContactInputContainer, TableCell } from './styles';

/**
 * Inline editable contact
 * contact value with type icon is displayed, with single click
 * allowing an existing contact for a master-data-person, or a desk (personal) contact
 * to be selected for replacement OR if Add Contact is chosen
 * a new contact will be created and selected for replacement
 * @param {*} props (contact, onCancel, recipient, deskAddressBookEntryContacts = [], communicationRecord, onSave)
 * @returns value of the contact, or editing components
 */
const InlineEditableContact = ({
  callTodo = {},
  recipient,
  deskAddressBookEntryContacts = [],
  onSave,
  canEdit = true,
}) => {
  // States
  const [innerContact, setInnerContact] = useState(callTodo?.contact);
  const [newContactType, setNewContactType] = useState('');
  const [newContactValue, setNewContactValue] = useState('');
  const [createNewContact, setCreateNewContact] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [contactInputError, setContactInputError] = useState(null);
  const [privateContacts, setPrivateContacts] = useState([]);
  const newContactValueRef = useRef(newContactValue);

  // this effect fetches the private contacts once edit-mode "editContact" is true
  useEffect(() => {
    if (editContact && recipient?._id) {
      getPrivateContacts(recipient._id).then((response) => {
        setPrivateContacts(response?.body?.privateContacts || []);
      });
    }
  }, [editContact, recipient]);

  useEffect(() => {
    newContactValueRef.current = newContactValue;
  }, [newContactValue]);

  // this sets the inner contact on load
  useEffect(() => {
    if (typeof callTodo?.contact !== 'undefined' && callTodo?.contact !== innerContact) {
      setInnerContact(callTodo?.contact);
    }
  }, [callTodo?.contact, innerContact]);

  const formattedContact = useMemo(() => formatContactForDisplay(innerContact), [innerContact]);

  //combine "master data" public contacts with "master data" private contacts (that their desk has access to)
  const masterDataContacts = useMemo(
    () => (recipient ? (recipient.contacts || []).concat(privateContacts) : []),
    [privateContacts, recipient]
  );

  //combine masterDataContacts with desk address book entries (just fall-through logic, should never be both?)
  const existingContacts = useMemo(
    () =>
      masterDataContacts
        .concat(deskAddressBookEntryContacts)
        .map(({ _id, contactType = 'Unknown', contact = '', primary }) => ({
          _id,
          contactType,
          value: contact,
          primary,
          private: contactType && !contact,
        })),
    [deskAddressBookEntryContacts, masterDataContacts]
  );

  const phoneErrorMessage = useMemo(() => {
    const asYouType = new AsYouType('ZZ');
    asYouType.input(newContactValue || '');

    const selectedCountry = PhoneNumber.getRegionCodeForCountryCode(asYouType.getCallingCode());

    let userRegion = 'US';
    try {
      userRegion = navigator.language.split('-')[1] || 'US';
    } catch {
      // could not get browser language
    }

    const format = selectedCountry === userRegion ? 'NATIONAL' : 'INTERNATIONAL';
    const phoneExamples = getExampleNumber(selectedCountry || userRegion, examples);

    let examplesString = '';
    if (phoneExamples) {
      examplesString = phoneExamples.format(format);
    }

    return 'Please enter a valid number, `' + examplesString + '`';
  }, [newContactValue]);

  const contactAddable = useMemo(
    () => recipient && recipient?._id?.length > 0 && recipient?.type !== 'Employee' && canEdit,
    [canEdit, recipient]
  );

  const tooltipMessage = useMemo(() => {
    switch (callTodo.recipientId?.type) {
      case 'Employee':
        return 'Contact information for an employee can only be edited (by them) in Workday.';
      case 'Client':
        return 'Please contact a client team member to make changes to this client profile.';
      default:
        return 'Phonesheet cannot edit Outlook (O) or Personal (P) contacts.';
    }
  }, [callTodo.recipientId?.type]);

  const handleExistingContactSelected = useCallback(
    ({ contactType, value }) => {
      const selectedContact = {
        contactType,
        contact: value.replace(/[- )(]/g, ''),
      };

      'contact' in callTodo || (callTodo.contact = {});
      const existing = /Phone|Fax Number/.test(selectedContact.contactType)
        ? existingContactPhone(recipient, selectedContact)
        : existingContactNotPhone(recipient, selectedContact);

      let contact;
      let contactInfo = value.replace(/[- )(]/g, '');
      if (existing) {
        contact = existing;
        onSave && onSave({ contact, contactInfo });
      } else {
        if (!recipient) {
          // a private contact
          contact = selectedContact;
          onSave && onSave({ contact, contactInfo });
        }
      }
    },
    [callTodo, onSave, recipient]
  );

  const handleNewContactCreated = useCallback(() => {
    const isOfficeExtensionOnly =
      newContactValueRef.current &&
      newContactValueRef.current.includes(';') &&
      newContactValueRef.current.split(';')[0].length === 0 &&
      newContactValueRef.current.split(';')[1].length > 0;
    if (isOfficeExtensionOnly) {
      setContactInputError('Please enter a valid contact');
    } else if (onSave) {
      onSave({
        ...callTodo,
        contact: { contact: newContactValueRef.current, contactType: newContactType },
        contactInfo: newContactValueRef.current,
        // occurrence_date: toUnixTime(new Date()),
      });
      setNewContactValue('');
    }
  }, [callTodo, newContactType, onSave]);

  const handleOnChangeContactInput = useCallback(({ contactType, contact }) => {
    setNewContactType(contactType);
    setNewContactValue(contact);
    setContactInputError(null);
  }, []);

  const handleOnCancelContactInput = useCallback(() => {
    setCreateNewContact(false);
    setInnerContact(callTodo?.contact);
    setNewContactValue('');
    setContactInputError(null);
  }, [callTodo?.contact]);

  const handleOnBlurContactInput = useCallback(() => {
    if (!newContactValueRef.current) {
      return;
    }

    const isPhoneOrFax = /Phone|Fax Number/.test(newContactType);
    const invalidPhoneOrFax =
      newContactType && isPhoneOrFax && !isValidPhoneNumber(newContactValueRef.current || '');
    if (invalidPhoneOrFax || (!isPhoneOrFax && !newContactValueRef.current)) {
      setContactInputError(isPhoneOrFax ? phoneErrorMessage : 'Please enter a valid contact');
    } else {
      handleNewContactCreated();
      setCreateNewContact(false);
      setContactInputError(null);
    }
  }, [handleNewContactCreated, newContactType, phoneErrorMessage]);

  return (
    <TableCell>
      {!createNewContact && (
        <>
          <ContactDisplayContainer
            id="contact-container"
            onClick={() => {
              setEditContact(true);
            }}
            editContact={editContact}
          >
            <span style={{ display: 'inline-block', margin: '3px 2px 0 0' }}>
              <ContactType type={formattedContact.contactType} inline />
            </span>
            <span
              data-cy={CALL_ROW.CONTACT}
              style={{ display: 'inline-block', maxWidth: 'calc(100% - 15px)' }}
            >
              <EllipsisText text={formattedContact.contact} />
            </span>
          </ContactDisplayContainer>
          {!!editContact && (
            <div>
              <ContactTypeSelector
                value={{
                  contactType: innerContact?.contactType || 'Unknown',
                  value: innerContact?.contact,
                }}
                existing={existingContacts}
                dropdownOnly
                isOpen={editContact}
                onClose={() => setEditContact(false)}
                // hide the 'add button' if Outlook contact (no recipient)
                // TODO: desk contacts also have no recipient and will get caught in this filter
                addable={contactAddable}
                tooltipMessage={tooltipMessage}
                cypressTags={{
                  existingContact: CONTACT_INPUT.EXISTING_CONTACT,
                  addContact: CONTACT_INPUT.ADD_CONTACT,
                  contactType: CONTACT_INPUT.CONTACT_TYPE,
                }}
                onChange={(selected) => {
                  if (!selected.value) {
                    setNewContactType(selected.contactType);
                    setCreateNewContact(true);
                  } else {
                    handleExistingContactSelected(selected);
                  }
                  setEditContact(false);
                }}
              />
            </div>
          )}
        </>
      )}
      {!!createNewContact && (
        <ContactInputContainer>
          <ContactInput
            inline
            focused
            contact={{ contact: newContactValue, contactType: newContactType }}
            onChange={handleOnChangeContactInput}
            onCancel={handleOnCancelContactInput}
            onBlur={handleOnBlurContactInput}
            existingContacts={existingContacts}
            error={contactInputError}
            cypressTags={{
              existingContact: CONTACT_INPUT.EXISTING_CONTACT,
              addContact: CONTACT_INPUT.ADD_CONTACT,
              contactType: CONTACT_INPUT.CONTACT_TYPE,
              phoneNumberInput: CONTACT_INPUT.PHONE_NUMBER_INPUT,
            }}
          />
        </ContactInputContainer>
      )}
    </TableCell>
  );
};

export default InlineEditableContact;
