import styled from 'styled-components/macro';
import { colors } from '@united-talent-agency/components';

export const StarIconTableElement = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 32px;
`;

export const StarIconContainer = styled.div`
  width: max-content;
  display: flex;
  cursor: pointer;
`;

export const CallTableRow = styled.tr`
  position: relative;
  height: 32px;
  border: 1px solid transparent;
  border-bottom: 1px solid ${colors.whiteSmoke};
  background-color: ${({ selected }) => (selected ? 'rgba(36, 149, 212, 0.1)' : colors.white)};
  box-shadow: 0px 0px 1px 1px transparent;

  &:hover {
    border-color: ${colors.linkBlue};
    box-shadow: 0px 0px 1px 1px ${colors.linkBlue};
  }

  > td {
    font-size: 12px;
    font-weight: 300;
    color: ${colors.text};
    padding-left: 2.5px;
    padding-right: 2.5px;
  }

  &:last-child {
    border-bottom: 1px solid ${colors.border};
  }
`;

export const ActionsTableCell = styled.td`
  white-space: nowrap;
  text-align: left;
  width: 60px;
`;

export const ActionButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ActionButton = styled.div`
  display: flex;
  margin-left: 10px;
  cursor: pointer;
`;
