import styled, { css } from 'styled-components/macro';
import { colors } from '@united-talent-agency/components';

export const TableCell = styled.td`
  min-width: 150px;
  text-overflow: ellipsis;
  font-weight: 300;
  cursor: pointer;

  div,
  span,
  a {
    max-width: unset;
  }
`;

export const ContactDisplayContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: -1px;
  height: 32px;
  border: 0.5px solid transparent;
  box-shadow: 0px 0px 0.5px 1px transparent;

  ${({ editContact }) =>
    editContact
      ? css`
          padding-left: 4px;
          width: 95%;
          border-collapse: unset;
          border-color: ${colors.linkBlue};
          box-shadow: 0px 0px 0.5px 1px ${colors.linkBlue};
        `
      : css`
          padding-left: 5px;
        `}
`;

export const ContactInputContainer = styled.div`
  position: absolute;
  margin-top: -16px;
  z-index: 3;
  background: white;
`;
