export const container = {
  width: '100%',
  maxWidth: '1000px',
  padding: '0 10px',
  margin: '0 auto',
};

export const mobileBreakpoint = '(max-width: 730px)';
export const smallBreakpoint = `(max-width: 1000px)`;
export const mediumBreakpoint = `(max-width: 1440px)`;
