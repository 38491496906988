import { requestSansStore } from '@united-talent-agency/julius-frontend-store';

/**
 * Fetches a representation of the specified resource
 *
 * @param {String} path: the specific resource (endpoint) to access.
 * @param {[Object]} query: parsed & packaged query parameters used to search and filter a resource
 * @returns {Promise.<{body: {data: [Object], meta: ResponseMeta}}>}
 **/
export const get = (path, query) => {
  return requestSansStore(path, 'GET', null, query);
};

/**
 * Creates a representation of the specified resource
 *
 * @param {String} path: the specific resource (endpoint) to access.
 * @param {Object} payload: the resource to create
 * @returns {Promise.<{body: {data: [Object], meta: ResponseMeta}}>}
 **/
export const post = (path, payload) => {
  return requestSansStore(path, 'POST', payload, null);
};

/**
 * Updates a representation of the specified resource
 *
 * @param {String} path: the specific resource (endpoint) to access.
 * @param {Object} payload: the updated resource & options used to control bulk-updates
 * @param {[Object]} query: parsed & packaged query parameters used to search and filter a resource
 * @returns {Promise.<{body: {data: [Object], meta: ResponseMeta}}>}
 **/
export const put = (path, payload, query) => {
  return requestSansStore(path, 'PUT', payload, query);
};

/**
 * Updates a portion of a representation of the specified resource
 *
 * @param {String} path: the specific resource (endpoint) to access.
 * @param {Object} payload: the partially updated resource & options used to control bulk-updates
 * @param {[Object]} query: parsed & packaged query parameters used to search and filter a resource
 * @returns {Promise.<{body: {data: [Object], meta: ResponseMeta}}>}
 **/
export const patch = (path, payload, query) => {
  return requestSansStore(path, 'PATCH', payload, query);
};

/**
 * Deletes a representation of the specified resource
 *
 * @param {String} path: the specific resource (endpoint) to access.
 * @param {Object} options: additional options used to control bulk-deletes
 * @param {[Object]} query: parsed & packaged query parameters used to search and filter a resource
 * @returns {Promise.<{body: {data: [Object], meta: ResponseMeta}}>}
 **/
export const remove = (path, options, query) => {
  return requestSansStore(path, 'DELETE', options, query);
};
