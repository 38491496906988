import * as React from 'react';
import { connect } from 'react-redux';
import { styled, helpers } from 'react-free-style';
import CustomScroll from 'react-custom-scroll';
import * as _ from 'lodash';
import Modal from 'react-modal';

import { Search } from '@united-talent-agency/julius-frontend-components';
import { searchArchive, clearArchive } from '@united-talent-agency/julius-frontend-store';

import { formatContact } from '../../../support/contact';
import * as elements from '../../../styles/elements';
import * as icons from '../../../styles/icons';
import { captureTelemetry, teleTags } from '../../../support/telemetry';

class ArchiveSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '',
    };
  }

  render() {
    const { styles, archives, user, dispatch, onPersonSelected } = this.props;
    const sortedArchives = archives && _.orderBy(archives, ['name']);

    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '700px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        overflow: 'hidden',
        boxShadow: '20px 20px 20px #bbb',
        border: '1px solid black',
      },
    };

    return (
      <Modal
        isOpen={this.props.isOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={() => {
          this.props.closeModal();
        }}
        style={customStyles}
      >
        <i
          className={styles.closeIcon + ' fa fa-times'}
          onClick={() => {
            this.props.closeModal();
          }}
        />
        <div className={styles.headerRow}>
          <span className={styles.title}> {'DialLog Data Archive'} </span>
        </div>
        <CustomScroll heightRelativeToParent="500px">
          <div className={styles.archiveContainer}>
            <div className={styles.archivesOverview}>
              <Search
                value={this.state.query}
                onSubmit={(query) => {
                  this.setState({ query });
                  dispatch(searchArchive(query));
                  captureTelemetry(dispatch, teleTags.searchArchives, user._id, query);
                }}
                className={styles.searchContainer}
                inputClassName={styles.searchInput}
                onChange={(value) => {
                  this.setState({ query: value });
                  dispatch(searchArchive(value));
                  captureTelemetry(dispatch, teleTags.searchArchives, user._id, value);
                }}
              />
            </div>
            <div className={styles.archives}>
              <table className={styles.archiveTable}>
                <tr className={styles.tableHeader}>
                  <th>NAME</th>
                  <th>CONTACTS</th>
                </tr>
                {sortedArchives.map((person, index) => {
                  return (
                    <tr key={index} className={styles.dataRow}>
                      <td className={styles.nameColumn}>
                        <div className={styles.nameField}>{person.name}</div>
                        <div className={styles.companyColumn}>{person.company}</div>
                        <div className={styles.buttonColumn}>
                          {!person.published ? (
                            <button
                              className={styles.actionButton}
                              onClick={async () => {
                                onPersonSelected && onPersonSelected(person);

                                captureTelemetry(
                                  dispatch,
                                  teleTags.importFromArchives,
                                  user._id,
                                  person
                                );
                                this.setState({ query: '' });
                                dispatch(clearArchive());
                                this.props.closeModal();
                              }}
                            >
                              <i className={styles.callButtonIcon} />
                            </button>
                          ) : (
                            <span>
                              <b>Available in phonesheet.</b>
                            </span>
                          )}
                        </div>
                      </td>
                      <td className={styles.contactColumn}>
                        <table>
                          <tr className={styles.subheader}>
                            <th className={styles.contactTd}>Type</th>
                            <th className={styles.contactTd}>Description</th>
                            <th className={styles.contactTd}>Contact</th>
                          </tr>
                          {person.contacts &&
                            person.contacts.map((contact, index) => {
                              return (
                                <tr key={index}>
                                  <td className={styles.contactTd}>{contact.contactType}</td>
                                  <td className={styles.contactTd}>{contact.description}</td>
                                  <td className={styles.contactTd}>
                                    {formatContact(contact.contactType, contact.contact)}
                                  </td>
                                </tr>
                              );
                            })}
                        </table>
                      </td>
                    </tr>
                  );
                })}
              </table>
            </div>
          </div>
        </CustomScroll>
      </Modal>
    );
  }
}

const withStyles = styled({
  closeIcon: {
    position: 'absolute',
    top: 5,
    left: 5,
    fontSize: '20px',
    color: 'black',
  },
  archivesOverview: {
    fontSize: '12px',
    marginLeft: '40px',
    width: 575,
    paddingTop: '25px',
  },
  archiveContent: {
    fontSize: '12px',
    marginLeft: '40px',
    paddingTop: '25px',
    paddingBottom: '10px',
    flexDirection: 'row',
  },
  archiveTitle: {
    width: '100%',
    display: 'flex',
    flex: 1,
  },
  archiveBody: {
    width: '100%',
    display: 'flex',
    flex: 1,
  },
  header: {
    flex: '0 1 100%',
    flexDirection: 'row',
    fontWeight: '100',
    color: '#999',
    width: '100%',
    textAlign: 'center',
    fontSize: '20px',
  },
  title: {
    textAlign: 'center',
    width: '100%',
    fontSize: '20px',
  },
  archives: {
    marginTop: 30,
  },
  headerRow: {
    display: 'flex',
    flex: 1,
    color: 'black',
    marginBottom: '10px',
  },
  archiveContainer: {
    color: '#999',
    backgroundColor: '#fff',
    minHeight: '500px',
  },
  dataColumn: {
    color: 'black',
    paddingRight: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 300,
    fontSize: 12,
  },
  nameColumn: {
    color: 'black',
    maxWidth: 150,
    verticalAlign: 'baseline',
    width: 150,
    paddingRight: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 300,
    fontSize: 12,
  },
  typeColumn: {
    color: 'black',
    maxWidth: 200,
    width: 200,
    paddingRight: 10,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 300,
    fontSize: 12,
  },
  contactColumn: {
    color: 'black',
    maxWidth: 450,
    width: 450,
    paddingRight: 10,
    verticalAlign: 'baseline',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: 300,
    fontSize: 12,
  },
  companyColumn: {
    color: '#777',
    fontSize: 10,
  },
  archiveTable: {
    width: '99%',
  },
  tableHeader: {
    borderBottom: '1px solid #999',
  },
  buttonColumn: {
    marginTop: 5,
  },
  nameField: {
    fontSize: 14,
    fontWeight: 800,
  },
  actionButton: helpers.merge(elements.button, elements.actionable, {
    width: 30,
    padding: 'none',
    height: 30,
    fontWeight: 'bold',
    border: '1px solid #333',
    borderRadius: 20,
    textTransform: 'uppercase',
  }),
  dataRow: {
    borderTop: '1px black solid',
    borderBottom: '1px black solid',
    paddingBottom: 10,
    paddingTop: 10,
  },
  contactTd: {
    paddingBottom: 8,
    paddingLeft: 8,
    paddingRight: 8,
  },
  callButtonIcon: helpers.merge(
    {
      marginTop: 2,
    },
    icons.plus
  ),
});
const withState = connect((state) => {
  const { archives, archiveCount, archiveFilter } = state.archive;
  return { archives, archiveCount, archiveFilter };
});
export default withState(withStyles(ArchiveSearch));
