import React, { useState, useRef } from 'react';
import { StatusPill, Icons, Tooltip, colors } from '@united-talent-agency/components';
import { patchCommunications } from '../../api/communications';
import {
  BulkStatusUpdateItem,
  StatusList,
  StatusMenuContainer,
  StatusSelectorContainer,
} from './styles';
import useOnBlur from '../../hooks/useOnBlur';
import { CALL_LIST } from '../../support/cypressTags';

const BulkUpdateStatus = ({
  selectAll,
  selectExcept = new Set(),
  statuses,
  filterParams,
  onUpdateApplied = () => {},
  onExit,
}) => {
  // Refs
  const wrapperRef = useRef(null);

  // States
  const [showBulkStatusUpdateList, setShowBulkStatusUpdateList] = useState(false);

  useOnBlur(wrapperRef, () => {
    setShowBulkStatusUpdateList(false);
  });

  const handleBulkUpdate = async (statusText) => {
    try {
      setShowBulkStatusUpdateList(false);

      const update = {
        filterParams,
        all: selectAll,
        except: Array.from(selectExcept),
        status: statusText,
      };

      const status = statuses.find((s) => s.status === statusText);

      if (['Initiate', 'Close'].includes(status.type)) {
        update.occurrence_date = new Date().getTime();
      }

      await patchCommunications(update);
      onUpdateApplied();
    } catch (err) {
      console.error(err);
      onExit && onExit();
    }
  };

  const someSelected = selectAll || selectExcept.size > 0;

  return (
    <div>
      <StatusSelectorContainer
        data-cy={CALL_LIST.BULK_UPDATE_STATUS}
        style={{
          cursor: `${someSelected ? 'pointer' : 'default'}`,
        }}
      >
        <StatusMenuContainer
          style={!someSelected ? { color: colors.disabledGrey } : {}}
          onClick={() => {
            setShowBulkStatusUpdateList(!showBulkStatusUpdateList);
          }}
        >
          <Tooltip
            text={!someSelected ? 'Select rows to update status on' : 'Set status on selected rows'}
            place="top"
          >
            <div style={{ paddingBottom: '3px' }}>
              Status <Icons.ChevronDown color={!someSelected ? colors.disabledGrey : undefined} />
            </div>
          </Tooltip>
          {someSelected && showBulkStatusUpdateList && (
            <StatusList ref={wrapperRef}>
              {/* An overhanging <br /> List of Statuses */}
              {statuses
                .filter((status) => status.status)
                .map((status) => (
                  <BulkStatusUpdateItem
                    key={status.status}
                    onClick={() => handleBulkUpdate(status.status)}
                  >
                    <StatusPill status={status.status} color={status.color} />
                  </BulkStatusUpdateItem>
                ))}
            </StatusList>
          )}
        </StatusMenuContainer>
      </StatusSelectorContainer>
    </div>
  );
};

export default BulkUpdateStatus;
