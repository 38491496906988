import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { getCompanyId, getCompanyName } from '../../../data/call-todo';
import { companyProfileUrl } from '../../../support/urls';
import { CALL_ROW } from '../../../support/cypressTags';
import EllipsisText from '../EllipsisText';

/**
 * Displays the callTodo company name if it is present.
 * @param {Object} item: callTodo item.
 * @param {Object} styles: provided styling.
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyName = ({ item, styles }) => {
  const { companyName: showCompanyName = false } = useFlags();

  const companyId = getCompanyId(item, showCompanyName);
  const companyName = getCompanyName(item, showCompanyName);

  return (
    <td className={styles.company}>
      <div data-cy={CALL_ROW.COMPANY} className={styles.person}>
        {companyId ? (
          <a
            href={`${companyProfileUrl}/${companyId}`}
            className={styles.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <EllipsisText text={companyName} />
          </a>
        ) : (
          <EllipsisText text={companyName} />
        )}
      </div>
    </td>
  );
};

export default CompanyName;
