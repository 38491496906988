import React, { useEffect, useState, useRef } from 'react';
import { styled as reactStyled } from 'react-free-style';
import { Tooltip } from '@united-talent-agency/components';

const EllipsisText = ({ text, styles, style = {} }) => {
  const wrapperRef = useRef();
  const [isOverflown, setIsOverflown] = useState(false);

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      setIsOverflown(
        wrapperRef.current.offsetWidth < wrapperRef.current.scrollWidth ||
          wrapperRef.current.offsetHeight < wrapperRef.current.scrollHeight
      );
    }
  }, [wrapperRef, text]);

  const content = (
    <div className={styles.text} ref={wrapperRef} style={style}>
      {text}
    </div>
  );

  return isOverflown ? <Tooltip text={text}>{content}</Tooltip> : content;
};

const withStyles = reactStyled({
  text: {
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export default withStyles(EllipsisText);
