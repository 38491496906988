import React from 'react';
import { StatusChangeHorizontal } from '@united-talent-agency/components';
import { CALL_ROW } from '../../../support/cypressTags';

/**
 * Allows the change of status for a given callTodo.
 *
 * @param {Object} callTodo: full callTodo object record.
 * @param {[Object]} statuses: array of possible statuses for the current desk.
 * @param {function} onSave: save hook that updates the callTodo.
 * @param {Object} styles: provided styling.
 * @returns {JSX.Element}
 * @constructor
 */

const Status = ({ callTodo, statuses, onSave, styles }) => (
  <td className={styles.status}>
    <StatusChangeHorizontal
      code={callTodo ? callTodo.status : ''}
      item={callTodo}
      statuses={statuses}
      cypressTag={CALL_ROW.STATUS}
      onChange={(statusText) => {
        const update = { status: statusText };
        const status = statuses.find((s) => s.status === statusText);

        if (callTodo.status === statusText && ['Initiate', 'Close'].includes(status.type)) {
          update.occurrence_date = new Date().getTime();
        }

        onSave(update);
      }}
      small
    />
  </td>
);

export default Status;
