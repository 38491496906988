import { useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 * Reference: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
const useOnBlur = (ref, fn) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        fn(event);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, fn]);
};

export default useOnBlur;
