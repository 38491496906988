import React from 'react';
import { Checkbox } from '@united-talent-agency/components';

// SUB-COMPONENTS
/**
 * Checkbox that allows for selecting the row for global actions
 * (ie bulk delete, bulk edit)
 *
 * @param {bool} selected: denotes checked status.
 * @param {function} onChange: function hook into the call-sheet that tracks
 *    what callTodos are selected.
 * @returns {JSX.Element}
 * @constructor
 */
const RowCheckbox = ({ selected, onChange }) => (
  <td>
    <Checkbox
      checked={selected}
      clearedBackground
      onChange={({ currentTarget: { checked } }) => {
        onChange && onChange(checked);
      }}
    />
  </td>
);

export default RowCheckbox;
