import { fromStringToE164, fromE164ToDisplay } from '@united-talent-agency/components';
import { searchDeskContacts } from '@united-talent-agency/julius-frontend-store';

export function formatContact(type, contact) {
  if (type && contact) {
    if (type === 'Email' || contact.includes('@')) {
      return contact;
    }
    contact = contact.replace(/[^\d]/g, '');
    if (contact.length === 4) {
      return 'x' + contact;
    } else if (contact.length === 10) {
      return contact.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else if (contact.length === 11) {
      return contact.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
    } else {
      return contact;
    }
  }
}

export const contactTypes = {
  'Office Phone': 'Work',
  'Home Phone': 'Home',
  'Mobile Phone': 'Mobile',
  'Assistant Phone': 'Asst. Phone',
  'Assistant Email': 'Asst. Email',
  'Fax Number': 'Fax',
  Email: 'Email',
  Message: 'Message',
  Unknown: 'Misc.',
};

export const existingContactNotPhone = (person, contact) => {
  if (!contact || !person || !person.contacts) {
    return false;
  }

  return person.contacts.find(
    (spc) =>
      contact.contact &&
      spc.contact &&
      contact.contact.trim().toLowerCase() === spc.contact.trim().toLowerCase()
  );
};

export const existingContactPhone = (person, contact) => {
  if (!contact || !person || !person.contacts) {
    return false;
  }

  const digits = contact.contact?.replace(/[^0-9]/g, '');
  return person.contacts.find((spc) => {
    const spcDigits = spc.contact?.replace(/[^0-9]/g, '');
    const regexTest = `^(\\d{1,3})?${spcDigits}$`;
    const spcDigitsRegex = new RegExp(regexTest);
    return (
      /Phone|Fax Number/.test(spc.contactType) && spcDigits?.length && spcDigitsRegex.test(digits)
    );
  });
};

export const formatContactForDisplay = (unformattedContact) => {
  const userRegion = navigator.language.split('-')[1];
  const formattedContact = Object.assign({}, unformattedContact);
  unformattedContact &&
    unformattedContact.contact &&
    /Phone|Fax Number/.test(unformattedContact.contactType) &&
    (formattedContact.contact = fromE164ToDisplay(
      fromStringToE164(unformattedContact.contact),
      userRegion,
      ' x'
    ));
  return formattedContact || '';
};

/**
 * Searches a desk for a particular recipient and returns that recipients
 * contacts via a callback function.
 *
 *
 * @param {string} id: callTodo object id
 * @param {string} deskId: desk object id
 * @param {{name: string, id: string}} recipient: person's contacts to look for
 * @param {function} dispatch: redux hook
 * @returns { Object || null }: recipient contacts or null.
 */
export const updateDeskAddress = (id, deskId, recipient, dispatch) => {
  // if there's a recipientName and not a recipientId, the call recipient is a desk address book entry
  if (id && recipient.name && recipient.id) {
    // to preemptively populate the contacts menu, search the desk address book for the recipientName
    dispatch(searchDeskContacts(deskId, recipient.name)).then((res) => {
      if (res?.body?.error) {
        console.error(res.body.error);
        return null;
      }

      const deskPeople = res.body || [];
      // the name search is a wildcard match and can return multiple people -
      // check for an exact match on name
      const deskPerson = deskPeople.find((person) => person.name === recipient.name);

      // add the contacts of an exact match to the deskContactResults to be concatenated
      // with the list of available contacts
      if (deskPerson) {
        return deskPerson.contacts;
      }
      return null;
    });
  }
};
