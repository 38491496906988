import React from 'react';

/**
 * Display of notes within the call sheet when they're not being edited
 * @param {string} notes: callTodo description
 * @param {string} id: callTodo document id
 * @param {Object} styles: provided styling classes
 * @returns {JSX.Element}
 * @constructor
 */
const StaticNotes = ({ notes, id, styles }) => (
  <td>
    <div className={styles.note}>
      <span className={styles.noteBorder} />
      <span id={`item_description_${id}`} className={styles.noteContent}>
        {notes}
      </span>
    </div>
  </td>
);

export default StaticNotes;
