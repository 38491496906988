import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { colors, Icons, NotesModal } from '@united-talent-agency/components';
import { CALL_ROW } from '../../../support/cypressTags';
import EllipsisText from '../EllipsisText';
import { useEffect } from 'react';

const Notes = ({ notes, onSave, canEdit = true }) => {
  const [displayNote, setDisplayNote] = useState(notes[0]?.note);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setDisplayNote(notes[0]?.note);
  }, [notes]);

  const handleSaveNotes = (updatedNotes) => {
    setDisplayNote(updatedNotes[0]?.note);
    if (JSON.stringify(updatedNotes) !== JSON.stringify(notes)) {
      onSave({ notes: updatedNotes });
    }
  };

  const toggleModal = (bool) => {
    setIsOpen(bool);
  };

  return (
    <TableCell
      onClick={() => {
        if (!isOpen) {
          toggleModal(true);
        }
      }}
    >
      <Container>
        <Pipe />
        {!!displayNote && <Icons.NoteIcon style={{ minWidth: 12, minHeight: 12, marginTop: 3 }} />}
        <NoteTextContainer
          data-cy={CALL_ROW.NOTES}
          style={{ whiteSpace: 'normal', maxWidth: '100%' }}
        >
          <EllipsisText
            text={displayNote}
            style={{
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              whiteSpace: 'normal',
            }}
          />
        </NoteTextContainer>
        {isOpen && (
          <NotesModal
            notes={notes}
            onSave={handleSaveNotes}
            handleCloseModal={() => toggleModal(false)}
            cypressTag={CALL_ROW.NOTES_INPUT}
            canEdit={canEdit}
          />
        )}
      </Container>
    </TableCell>
  );
};

const TableCell = styled.td({
  position: 'relative',
});

const Container = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  maxWidth: '100%',
});

const NoteTextContainer = styled.span({
  maxWidth: '350px',
  minWidth: '150px',
  float: 'left',
  paddingLeft: '5px',
  lineHeight: '1.5em',
  height: '3em',
  maxHeight: '3em',
});

const Pipe = styled.span({
  display: 'inline-block',
  width: 1,
  height: 20,
  backgroundColor: colors.whiteSmoke,
  marginRight: '5px',
});

export default Notes;
