import { searchOutlook } from '@united-talent-agency/julius-frontend-store';

export const getOutlookContacts = (
  searchText,
  deskId,
  dispatch,
  _searchOutlook = searchOutlook,
  _OutlookResultTransformer = OutlookResultTransformer
) => {
  return dispatch(_searchOutlook(searchText, deskId))
    .then((results) => {
      return results && results.body
        ? results.body.map((r) => {
            return _OutlookResultTransformer(r);
          })
        : [];
    })
    .catch((err) => {
      console.error(err);
      return [];
    });
};

const _getAddresses = (person) => {
  const addresses = [];
  ['homeAddress', 'otherAddress', 'businessAddress'].forEach((address) => {
    let type = '';

    switch (address) {
      case 'homeAddress':
        type = 'Home';
        break;
      case 'businessAddress':
        type = 'Work';
        break;
      default:
        type = 'Unknown';
        break;
    }

    if (person[address] && person[address].street) {
      addresses.push({
        address: person[address].street,
        state: person[address].state,
        city: person[address].city,
        country: person[address].countryOrRegion,
        zip: person[address].postalCode,
        type,
      });
    }
  });
  return addresses;
};

const _getContacts = (person) => {
  const contacts = [];

  person.emailAddresses.forEach((a) => {
    contacts.push({
      contactType: 'Email',
      contact: a.address,
    });
  });

  person.businessPhones.forEach((p) => {
    contacts.push({
      contactType: 'Office Phone',
      contact: p,
    });
  });

  person.homePhones.forEach((p) => {
    contacts.push({
      contactType: 'Home Phone',
      contact: p,
    });
  });

  if (person.mobilePhone) {
    contacts.push({
      contactType: 'Mobile Phone',
      contact: person.mobilePhone,
    });
  }

  person.imAddresses.forEach((im) => {
    contacts.push({
      contactType: 'Message',
      contact: im,
    });
  });

  return contacts;
};

export const OutlookResultTransformer = (result) => {
  return {
    _id: result.id,
    name: result.displayName || `${result.givenName} ${result.surname}`,
    title: result.jobTitle,
    companyId: { name: result.companyName || '' },
    contacts: _getContacts(result),
    addresses: _getAddresses(result),
    note: result.personalNotes,
    outlook: true,
    type: 'Outlook',
  };
};
