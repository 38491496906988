import React from 'react';
import { useState, useRef } from 'react';
import { HEADER } from '../../support/cypressTags';
import useOnBlur from '../../hooks/useOnBlur';
import { MenuItemIconContainer, MenuColumn, MenuButton, MenuContainer, MenuItem } from './styles';

const HeaderMenu = ({
  menuOptions = [],
  MenuMainIcon = () => 'MENU',
  defaultOpenState = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpenState);

  const wrapperRef = useRef(null);
  useOnBlur(wrapperRef, (event) => {
    const clickedMainIcon = Object.values(event.target).some(
      (clickedElement) => clickedElement.id === 'menu-main-icon'
    );

    !clickedMainIcon && setIsOpen(false);
  });

  const renderIcon = ({ MenuOptionIcon, props }) => {
    return (
      <MenuItemIconContainer>
        <MenuOptionIcon inline width={15} height={15} {...props} />
      </MenuItemIconContainer>
    );
  };

  return (
    <MenuColumn>
      <MenuButton data-cy={HEADER.MENU_DROPDOWN}>
        <MenuMainIcon
          id="menu-main-icon"
          onClick={() => setIsOpen(!isOpen)}
          width={35}
          height={35}
        />
      </MenuButton>
      {isOpen && (
        <MenuContainer ref={wrapperRef}>
          {menuOptions.map((option, index) => {
            return (
              <MenuItem
                key={index}
                data-cy={HEADER.MENU_ITEM}
                id="header-menu-option"
                onClick={() => {
                  option.onSelect && option.onSelect();
                  setIsOpen(false);
                }}
              >
                {option.MenuOptionIcon && renderIcon(option)}
                {option.name}
              </MenuItem>
            );
          })}
        </MenuContainer>
      )}
    </MenuColumn>
  );
};

export default HeaderMenu;
