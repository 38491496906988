import styled from 'styled-components/macro';

export const BoldSpan = styled.span`
  font-weight: 700;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  right: 15;
`;

export const ConfirmationText = styled.p`
  margin: 30;
  text-align: center;
  font-size: 14;
`;

export const SaveButtonContainer = styled.div`
  margin: 0 38px 12px 0;
  justify-content: flex-end;
  display: flex;
  position: relative;
`;

export const ModalTitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24;
  font-weight: 300;
`;
