import React from 'react';
import { Icons, Tooltip } from '@united-talent-agency/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CALL_ROW } from '../../../support/cypressTags';
import { ActionsTableCell, ActionButtonWrapper, ActionButton } from '../styles';

const ICON_SIZE = 14;

/**
 * Contains the callRow icon-actions for showing history, deleting the callTodo and opening the
 * edit mode
 *
 * @param {string} id: callTodo record id
 * @param {function} onDelete: hook for deleting the callTodo
 * @param {function} onEdit: hook for opening the edit mode for the callTodo
 * @param {function} showHistory: hook for opening the history modal
 * @returns {JSX.Element}
 * @constructor
 */
const Actions = ({ id, onDelete, onEdit, showHistory }) => {
  const { notesModal: showNotesModal = true } = useFlags();

  return (
    <ActionsTableCell>
      <ActionButtonWrapper>
        {/** TEMPORARY STINT - Remove edit button when field-level (onBlur) save enabled **/}
        {showNotesModal ? null : (
          <ActionButton id={`edit-button-${id}`} onClick={onEdit}>
            <Icons.PencilIcon width={ICON_SIZE} height={ICON_SIZE} />
          </ActionButton>
        )}
        <Tooltip text="Call History" place="bottom">
          <ActionButton
            id={`history-button-${id}`}
            data-cy={CALL_ROW.CALL_HISTORY}
            onClick={() => showHistory(true)}
          >
            <Icons.HistoryIcon width={ICON_SIZE} height={ICON_SIZE} />
          </ActionButton>
        </Tooltip>
        <ActionButton id={`delete-button-${id}`} onClick={onDelete} data-cy={CALL_ROW.DELETE_CALL}>
          <Icons.DeleteIcon inline width={ICON_SIZE} height={ICON_SIZE} />
        </ActionButton>
      </ActionButtonWrapper>
    </ActionsTableCell>
  );
};

export default Actions;
